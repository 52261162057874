import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import { canUseDOM, trackEvent } from '@surfline/web-common';

import config from 'config';
import ErrorBoundary from 'components/ErrorBoundary';
import ErrorMessage from 'components/ErrorMessage';
import {
  Facebook,
  Instagram,
  MSWIcon,
  SurflineLogo,
  TikTok,
  Twitter,
  YouTube,
} from 'components/Icons';
import { OneTrustLink } from 'components/OneTrust';
import WavetrakLink from 'components/WavetrakLink';
import { useUserEntitlementStatus } from 'selectors/user';

import styles from './Footer.module.scss';

interface FooterLinkProps {
  href: string;
  isExternal?: boolean;
  text: string;
}

const handleClick = ({ href, text }: FooterLinkProps) =>
  trackEvent('Clicked Link', {
    linkLocation: 'footer',
    linkUrl: href,
    linkName: text,
  });

const FooterLink = ({ href, isExternal = true, text }: FooterLinkProps) => (
  <WavetrakLink
    href={href}
    isExternal={isExternal}
    onClick={() => handleClick({ href, text })}
    prefetch={!isExternal ? false : undefined}
  >
    <Typography variant="body1" component="span">
      {text}
    </Typography>
  </WavetrakLink>
);

interface FooterContentProps {
  isSmall?: boolean;
  isVisible?: boolean;
}

const FooterContent: React.FC<FooterContentProps> = ({ isSmall = false, isVisible = true }) => {
  const footerClassNames = classNames({
    [styles.container]: true,
    [styles.containerSmall]: isSmall,
  });

  const handleConsentManagerButtonClick = () =>
    trackEvent('Clicked Link', {
      linkLocation: 'footer',
      linkName: 'Do Not Sell My Personal Information',
    });

  const theme = useTheme();
  const isEntitled = useUserEntitlementStatus();

  return (
    <footer
      className={classNames(styles.footer, { [styles.footerHidden]: !isVisible })}
      data-testid="footer-content"
    >
      <div className="sl-section-container">
        <div className={footerClassNames}>
          <div className={styles.icons}>
            <SurflineLogo className={styles.iconsLogo} fill={theme.palette.secondary.main} />
            <ul className={styles.socialLogos}>
              <li>
                <a
                  href="https://www.facebook.com/Surfline/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Facebook />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/surfline/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Instagram />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/surfline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <YouTube />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/surfline" target="_blank" rel="noopener noreferrer">
                  <Twitter />
                </a>
              </li>
              <li>
                <a
                  href="https://tiktok.com/@surfline_official"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TikTok />
                </a>
              </li>
            </ul>
          </div>
          <ul className={styles.linkGroup}>
            <li>
              <Typography variant="callout1" component="h4">
                Company
              </Typography>
            </li>
            <li>
              <FooterLink href="https://careers.surfline.com" text="Careers" />
            </li>
            <li>
              <FooterLink href="https://go.surfline.com/whatsnew" text="What's New" />
            </li>
            <li>
              <FooterLink href="https://go.surfline.com/ads" text="Advertise" />
            </li>
            <li>
              <FooterLink href="https://go.surfline.com/cam-host" text="Host a Camera" />
            </li>
          </ul>
          <ul className={styles.linkGroup}>
            <li>
              <Typography variant="callout1" component="h4">
                Support
              </Typography>
            </li>
            <li>
              <FooterLink href="https://support.surfline.com" text="Support Center" />
            </li>
            <li>
              <FooterLink href={`${config.surflineHost}/account`} text="My Account" />
            </li>
            {!isEntitled && (
              <li>
                <FooterLink href={`${config.surflineHost}/upgrade`} text="Get Premium" />
              </li>
            )}
            <li>
              <FooterLink href={`${config.surflineHost}/gift-cards`} text="Gift Cards" />
            </li>
          </ul>
          <ul className={styles.copyright}>
            <li className={styles.copyrightLogo}>
              <SurflineLogo fill={theme.palette.secondary.main} />
            </li>
            <li>
              <Typography
                variant="body1"
                component="span"
              >{`©${new Date().getFullYear()} Surfline\\Wavetrak, Inc.`}</Typography>
            </li>
            <li>
              <FooterLink href="/terms-of-use" isExternal={false} text="Terms of Use" />
              <Typography variant="body1" component="span">
                {' '}
                and{' '}
              </Typography>
              <FooterLink href="/privacy-policy" isExternal={false} text="Privacy Policy" />.
            </li>
            <li>
              <OneTrustLink onClick={() => canUseDOM && handleConsentManagerButtonClick()} />
            </li>
          </ul>
          <div className={styles.iconsMSW}>
            <MSWIcon className={styles.msw} fill={theme.palette.text.primary} />
          </div>
        </div>
      </div>
    </footer>
  );
};

export interface FooterProps extends FooterContentProps {
  isError?: boolean;
}

export const Footer: React.FC<FooterProps> = (props) => {
  const { isError } = props;
  return (
    <div data-testid="footer">
      <ErrorBoundary
        error={isError}
        render={() => (
          <Box className={styles.footerError} data-testid="footer-error">
            <ErrorMessage dataTestId="error-message" />
          </Box>
        )}
      >
        <FooterContent {...props} />
      </ErrorBoundary>
    </div>
  );
};

export default Footer;
