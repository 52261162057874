import React, { useCallback, useMemo } from 'react';
import ForecastHeader from 'components/ForecastHeader';
import { useRouter } from 'next/router';
import { shallowEqual } from 'react-redux';
import { trackEvent } from '@surfline/web-common';

import { type PageType, PageTypes } from 'components/ForecastHeader/hooks/usePageDetails';
import PageLoading from 'components/PageLoading';
import SwellEventCardContainer from 'components/SwellEventCardContainer/SwellEventCardContainer';
import {
  getSubregionLoading,
  getSubregionOverviewData,
  getSubregionOverviewLoading,
  useSubregionForecastArticles,
} from 'selectors/subregion';
import getSwellEvents from 'selectors/swellEvents';
import { useAppSelector } from 'stores/hooks';
import type { SpotReportData } from 'types/spot';
import type { SubregionFeed } from 'types/subregion';
import type { SwellEvents } from 'types/swellEvents';

interface Props {
  isChartsPage?: boolean;
}

const SubregionForecastContainer: React.FC<Props> = ({ children, isChartsPage }) => {
  const router = useRouter();
  const overviewData = useAppSelector(getSubregionOverviewData, shallowEqual);
  const forecastArticles: SubregionFeed['articles'] = useSubregionForecastArticles();
  const swellEvents = useAppSelector(getSwellEvents) as SwellEvents;
  const subregionLoading = useAppSelector(getSubregionLoading);
  const overviewLoading = useAppSelector(getSubregionOverviewLoading);

  const { native, spotId, subregionId } = router.query;
  const isLoading = subregionLoading || overviewLoading;
  const spots = overviewData?.spots;
  const primarySpotId = overviewData?.primarySpot;

  const associatedSpotReport =
    spots?.find((spotReport: SpotReportData) => spotReport._id === primarySpotId) || spots?.[0];

  const conditions = associatedSpotReport?.conditions;

  const persistedSpotDetails = useMemo(
    () => spots?.find((spotReport: SpotReportData) => spotReport._id === spotId),
    [spots, spotId],
  );

  const primarySpotDetails = useMemo(
    () => spots?.find((spotReport: SpotReportData) => spotReport._id === primarySpotId),
    [spots, primarySpotId],
  );

  const onClickCard = useCallback(
    (swellEvent) => {
      trackEvent('Clicked Swell Alert Card', {
        title: swellEvent.name,
        contentType: 'Swell Alert',
        basins: swellEvent.basins.join(','),
        locationCategory: 'Region Forecast Page - Top',
        destinationUrl: swellEvent.permalink,
        subregionId,
        subregionName: overviewData.name,
      });
    },
    [overviewData, subregionId],
  );

  return (
    <div className="sl-subregion-forecast-page">
      {native !== 'true' && (
        <section className="sl-section-container">
          {!isChartsPage && swellEvents.length ? (
            <SwellEventCardContainer
              onClickCard={(swellEvent) => onClickCard(swellEvent)}
              events={swellEvents}
            />
          ) : null}
          <ForecastHeader
            conditions={conditions?.value ?? ''}
            forecastArticles={forecastArticles}
            hasLiveCam={associatedSpotReport?.cameras?.length}
            isRegionalCharts={!spotId}
            pageTitle={`${overviewData?.name}`}
            pageType={(isChartsPage ? PageTypes.CHARTS : PageTypes.REGIONAL_ANALYSIS) as PageType}
            spot={persistedSpotDetails || primarySpotDetails}
            subregionOverview={overviewData}
          />
          {isLoading && <PageLoading />}
        </section>
      )}
      {children}
    </div>
  );
};

export default SubregionForecastContainer;
