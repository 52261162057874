import React from 'react';

interface Props {
  className?: string;
}

export const PlayIcon = ({ className }: Props) => (
  <svg
    className={className}
    data-testid="play-icon"
    fill="none"
    height="52"
    viewBox="0 0 52 52"
    width="52"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      opacity="0.7"
      x="0.720703"
      y="0.47998"
      width="51.04"
      height="51.04"
      rx="25.52"
      fill="#171717"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.6396 24.3906C32.8292 25.134 32.8292 26.8664 31.6396 27.6099L24.3593 32.1601C23.095 32.9502 21.4551 32.0413 21.4551 30.5504V21.45C21.4551 19.9591 23.095 19.0502 24.3593 19.8403L31.6396 24.3906Z"
      fill="#FAFAFA"
    />
  </svg>
);

export default PlayIcon;
