import React, { useState } from 'react';
import classNames from 'classnames';
import { useMount } from 'react-use';
import { Typography } from '@mui/material';
import { nrNoticeError } from '@surfline/web-common';

import { Chevron } from 'components/Icons';
import WavetrakLink from 'components/WavetrakLink';
import type { TaxonomyNode } from 'types/header';
import type { UserSettings } from 'types/user';
import { useTreatments } from 'utils/treatments';
import { fetchSpotReportViews } from 'common/api/spot';
import { StaticLink } from '../../defaultLinks';
import RenderSpot from '../RenderSpot';
import RenderSubregion from '../RenderSubregion';

import styles from './RecentlyVisited.module.scss';

interface RecentlyVisitedProps {
  items: Array<TaxonomyNode>;
  listType?: string;
  loggedIn?: boolean;
  onClickLink?: (clickProperties: any, closeMobileMenu: boolean) => void;
  signInUrl: string;
  staticLinks?: Array<StaticLink>;
  userSettings: UserSettings | null;
}

const RecentlyVisited = ({
  items,
  listType = '',
  loggedIn = false,
  onClickLink,
  signInUrl,
  staticLinks = [],
  userSettings,
}: RecentlyVisitedProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [nodes, setNodes] = useState<Array<any>>([]);
  const treatments = useTreatments();

  const toggleShowList = () => {
    setIsOpen((prevState) => !prevState);
  };

  const headerClassName = () =>
    classNames({
      [styles.header]: true,
      [styles['header--open']]: isOpen,
    });

  const listClassName = () =>
    classNames({
      [styles.list]: true,
      [styles['list--hidden']]: !isOpen,
    });

  const onClickHandler = () => {
    if (onClickLink) {
      onClickLink(
        {
          locationCategory: 'Recently Visited',
          clickEndLocation: 'sign-in',
          destinationUrl: signInUrl,
          completion: true,
          linkName: 'Sign in or create account',
        },
        true,
      );
    }
  };

  const onClickLinkHandler = (clickProperties: any, closeMobileMenu: boolean) => {
    if (onClickLink) {
      onClickLink(
        {
          ...clickProperties,
          locationCategory: 'Recently Visited',
        },
        closeMobileMenu,
      );
    }
  };

  const onClickStaticHandler = (link: any) => () => {
    if (onClickLink) {
      onClickLink(
        {
          locationCategory: 'Tools',
          clickEndLocation: 'Tools',
          destinationUrl: link?.href,
          completion: true,
          linkName: link?.display,
        },
        true,
      );
    }
  };

  useMount(async () => {
    const newNodes = items || [];
    if (listType === 'subregions') {
      setNodes(newNodes);
    } else {
      try {
        const spotIds = newNodes.map((spot) => spot._id || '');
        const { data } = await fetchSpotReportViews(spotIds, {
          units: userSettings?.units,
        });
        setNodes(
          newNodes.map((item) => ({
            ...item,
            ...data.find((d: any) => d._id === item._id),
          })),
        );
      } catch (error: any) {
        nrNoticeError(error, {});
      }
    }
  });

  return (
    <div className={styles.recentlyVisited} data-testid="recently-visited">
      <button type="button" className={headerClassName()} onClick={toggleShowList}>
        <Typography className={styles.buttonWrapper} variant="h5">
          Recently Visited
          <Chevron className={styles.chevron} direction="right" />
        </Typography>
      </button>
      <div className={listClassName()}>
        {(() => {
          if (!loggedIn) {
            return (
              <Typography className={styles.signin} variant="body2">
                <WavetrakLink
                  className={styles.link}
                  href={signInUrl}
                  isExternal
                  onClick={onClickHandler}
                >
                  Sign in or create account
                </WavetrakLink>
                {` to view your recently visited ${listType}`}
              </Typography>
            );
          }
          if (listType === 'subregions') {
            return nodes
              .filter((node) => !!node._id && !!node.name)
              .map((node) => (
                <RenderSubregion subregion={node} key={node._id} onClickLink={onClickLinkHandler} />
              ));
          }
          if (listType === 'spots') {
            return nodes
              .filter((node) => !!node._id && !!node.name)
              .map((node) => (
                <RenderSpot spot={node} key={node._id} onClickLink={onClickLinkHandler} />
              ));
          }
          return null;
        })()}
      </div>
      {listType === 'spots' && (
        <div className={styles.staticLinks}>
          {staticLinks?.slice(0, 4).map((link) => {
            if (treatments && link.splitTreatment && treatments[link.splitTreatment] !== 'on') {
              return null;
            }
            return (
              <Typography
                className={styles.staticLink}
                component={WavetrakLink}
                href={link?.href ?? undefined}
                isExternal={link?.isExternal}
                key={link.display}
                onClick={onClickStaticHandler(link)}
                rel="noreferrer"
                target={link?.newWindow ? '_blank' : undefined}
                variant="body2"
              >
                {link.display}
              </Typography>
            );
          })}
        </div>
      )}
      <div className={styles.staticLinks}>
        <Typography className={styles.header} variant="h5">
          Forecast Tools
        </Typography>
        {staticLinks?.slice(4, 6).map((link) => (
          <Typography
            className={styles.staticLink}
            component={WavetrakLink}
            href={link?.href ?? undefined}
            isExternal={link?.isExternal}
            key={link.display}
            onClick={onClickStaticHandler(link)}
            rel="noreferrer"
            target={link.newWindow ? '_blank' : undefined}
            variant="body2"
          >
            {link.display}
          </Typography>
        ))}
      </div>
    </div>
  );
};

export default RecentlyVisited;
