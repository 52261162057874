/* istanbul ignore file */
import { canUseDOM, getWindow } from '@surfline/web-common';

/**
 * @param {Error} error
 */
const shouldIgnoreError = (error) => {
  const { message, stack } = error;

  // ignore error from reaching an unsupported min map zoom
  if (message?.includes("undefined is not an object (evaluating 'b[b.length-1].step')")) {
    return true;
  }

  // Ignore errors from Chrome Extensions
  if (stack?.includes('chrome-extension://')) {
    return true;
  }

  // If it's some unknown error originating from google SDK, then ignore it
  if (
    stack?.includes('imasdk.googleapis.com') ||
    stack?.includes('googleads.g.doubleclick.net') ||
    message?.includes('Network timeout //imasdk.googleapis.com/js/sdkloader/ima3.js')
  ) {
    return true;
  }

  // If it's an error originating from profitwell, loaded by segment destinations, then ignore it
  if (stack?.includes('profitwell.com')) {
    return true;
  }

  // Ignore expected JW Player Errors
  if (message?.includes("Cannot read properties of undefined (reading 'realBitrate')")) {
    return true;
  }

  // Ignore expected Video.JS errors
  if (
    message?.includes("Cannot read properties of undefined (reading 'end')") ||
    message?.includes("Cannot read property 'end' of undefined") ||
    message?.includes('s is undefined') ||
    message?.includes('Invalid target for null#on; must be a DOM node or evented object') ||
    message?.includes('The play() request was interrupted by a call to pause()') ||
    message?.includes('The play() request was interrupted by a new load request')
  ) {
    return true;
  }

  // Ignore issues from SHE Media / https://ads.blogherads.com/static/blogherads.js
  if (
    message?.includes("undefined is not an object (evaluating 'n.length')") ||
    message?.includes("Cannot read properties of undefined (reading 'length')") ||
    message?.includes("Cannot read properties of null (reading 'postMessage')") ||
    message?.includes('n is undefined') ||
    message?.includes("Can't find variable: tlAdhesion") ||
    message?.includes('tlAdhesion is not defined') ||
    message?.includes('trackAdhesionQuatiles.dispatchQuartileEvents is not a function') ||
    message?.includes(
      "undefined is not an object (evaluating 'd.WindowAdaptor.workingDocument().activeElement')",
    ) ||
    message?.includes('WindowAdaptor.workingDocument() is undefined') ||
    message?.includes('Unhandled Promise Rejection: {"isTrusted":true') ||
    message?.includes("null is not an object (evaluating 'this.topWindow().scrollX')") ||
    message?.includes(
      "null is not an object (evaluating 'this.topWindow().removeEventListener')",
    ) ||
    message?.includes("null is not an object (evaluating '(this.xdom?t:t.parent).document')") ||
    message?.includes("Can't find variable: _AutofillCallbackHandler") ||
    message?.includes("Can't find variable: setTimeout") ||
    message?.includes('setTimeout is not defined') ||
    message?.includes('setTimeout is not a function') ||
    message?.includes(`null is not an object (evaluating 'adChoicesText.className="invisible"')`) ||
    message?.includes(
      "Uncaught TypeError: Cannot read properties of undefined (reading 'triggered')",
    ) ||
    message?.includes(
      "undefined is not an object (evaluating 'Object.keys(p1086785317[p1086785283[oIndex4_]])'",
    ) ||
    stack?.includes('ads.blogherads.com') ||
    stack?.includes('apstag.js') ||
    stack?.includes('cdn.adsafeprotected.com') ||
    stack?.includes('cdn.browsiprod.com') ||
    stack?.includes('cdn.flashtalking.com') ||
    stack?.includes('p.ad.gt') ||
    stack?.includes('static.yieldmo.com') ||
    stack?.includes('tagan.adlightning.com') ||
    stack?.includes('tahoe-analytics.publishers') ||
    stack?.includes('d15kdpgjg3unno.cloudfront.net')
  ) {
    return true;
  }

  // Ignore other non-actionable errors.
  if (
    message?.includes("Cannot read properties of undefined (reading '_leaflet_pos')") || // Internal Leaflet bug
    message?.includes("undefined is not an object (evaluating 't._leaflet_pos')") || // Internal Leaflet bug
    message?.includes('NetworkError when attempting to fetch resource') ||
    message?.includes(
      "Failed to execute 'querySelectorAll' on 'Document': 'div:has(> iframe[id=",
    ) || // not a valid selector
    message?.includes('ResizeObserver loop limit exceeded') ||
    message?.includes('ResizeObserver loop completed with undelivered notifications.') ||
    message?.includes(
      'Blocked a frame with origin "https://www.surfline.com" from accessing a cross-origin frame.',
    ) ||
    message?.includes(
      'Blocked a restricted frame with origin "https://www.surfline.com" from accessing another frame.',
    ) ||
    // TODO: research indicates that this createHTMLCallback error can indicate memory leaks so we want to remove this exclusion after resolving memory leaks.
    // Given that we don't know the source of this, and we don't ever call 'CreateHTMLCallback' in any of our code it will be challenging to solve the root cause.
    // This error is seen massive spikes of up to (65K in a few minutes) and appear to be from one session.
    // It seems like there's an infinite loop or something that sends this error to NewRelic repeatedly and then it dissappears for hours or days.
    message?.includes(
      "Failed to execute 'invoke' on 'CreateHTMLCallback': The provided callback is no longer runnable",
    )
  ) {
    return true;
  }

  // Ignore non-descriptive script errors
  // These errors are related to third party scripts
  if (message?.includes('Script error') && !stack?.includes('cdn-surfline.com')) {
    return true;
  }

  return false;
};

const setNewRelicErrorHandler = () => {
  if (canUseDOM) {
    const win = getWindow();

    if (win?.newrelic) {
      win.newrelic.setErrorHandler(shouldIgnoreError);
    }

    if (win && !win.onerror) {
      win.onerror = (message, _source, _lineno, _colno, error) => {
        // Include this error handler for uncaught errors that happen within scripts.
        // returning true cancels the error from propagating and stops it from being sent to NewRelic
        console.error('win.onerror handler', message, error, _source, _lineno, _colno);
        return shouldIgnoreError({ message });
      };
    }
  }
};

export default setNewRelicErrorHandler;
